import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const DolphinBombs = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">Dolphin Adam Bombs (Unofficial)</h1>
          <div className="image">
            <StaticImage src="../images/dolphin-adam-bombs.jpg" alt="Whale Adam Bombs" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>Created in 2022, Dolphin Adam is a community created icon to celebrate and reward those who had conviction in the vision of Adam Bomb Squad. It is available for those who hold 10 or more Adam Bomb Squad NFTs - as a declaration of their status.</p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="dolphin-adam-bombs-unofficial" project-slug="main" contract-network="1" contract-address="0x635fAc3680E183EB5A025e5D36c6FD9535Cc5476" eligible-check="yes" infura-id="3ed982906a2d473f8918f0730b2ea194"></hashku-mint>
    </PageLayout>
  );
};

export default DolphinBombs;
